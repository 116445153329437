import EventBus from '@/util/EventBus'
import getEnv from '@/util/env'
import HeadboardType from '../../../../../models/whatsapp-editor/HeadboardType.js'
import { TextFormatParser } from '@/classes/TextFormatParser.js'
import HeadboardOption from '../../../../../models/whatsapp-editor/HeadBoardOption'

export default {
  name: 'MessagePreview',
  props: [
    'campaign',
    'preview',
    'contactsPhone',
    'fields',
    'availableForms',
    'availableUrls',
    'regex',
    'canUseCustomSender',
    'selectedAutoAnswerId',
  ],
  data: function () {
    return {
      generatedUrls: [],
      textFormatParser: new TextFormatParser(),
      navigationDrawerOption: {
        show: false,
      },
      navigationDrawerList: {
        show: false,
      },
    }
  },
  computed: {
    responses: function () {
      return this.campaign.sendings[0].channel.callToActionResponses.concat(
        this.campaign.sendings[0].channel.automaticResponses)
    },
    responseSeeAll: function () {
      return this.responses.length > 2
    },
    hasLocalizationData () {
      return this.campaign.sendings[0].channel.localizationData
    },
    isHeaderOption () {
      return HeadboardType.isHeaderOption(this.campaign.sendings[0].channel.autoAnswer.answerType)
    },

    isCallToActionOption () {
      return HeadboardType.isCallToActionOption(this.campaign.sendings[0].channel.autoAnswer.answerType)
    },
    senderPreview: function () {
      const sender = this.campaign.sendings[0].channel.sender
      if (!sender) {
        return this.$t('Remitente').toString()
      } else if (sender === 'Test' && !this.canUseCustomSender) {
        return this.$t('Automático').toString()
      }
      return sender
    },
    headboardPreview: function () {
      if (this.preview.contactsPhone.length > 0) {
        if (!this.preview.contactPreview) {
          this.preview.contactPreview = this.preview.contactsPhone[0]
        }
        return this.changeHeadboardPreview(this.preview.contactPreview)
      }

      return this.campaign.sendings[0].channel.headboard
    },
    messagePreviewAuth: function () {
      const customVerificationCodeField = this.campaign.sendings[0].channel.customVerificationCodeField
      return this.campaign.sendings[0].channel.message.replace('{COL+}', '<strong>' + (customVerificationCodeField ? this.preview.contactsFieldsValue[this.preview.contactPreview][customVerificationCodeField] : '{COL+}') + '</strong>')
    },
    messagePreview: function () {
      if (!this.campaign.sendings[0].channel.message) {
        return this.$t('Contenido del WhatsApp').toString()
      }

      if (this.preview.contactsPhone.length > 0) {
        if (!this.preview.contactPreview) {
          this.preview.contactPreview = this.preview.contactsPhone[0]
        }
        return this.changeWhatsAppPreview(this.preview.contactPreview)
      }

      let message = this.campaign.sendings[0].channel.message

      const matches = message.match(/({FORM_[0-9]+})/g)
      if (matches) {
        matches.forEach(match => {
          const formId = match.match(/([0-9]+)/g)
          message = message.replace('{FORM_' + formId + '}', '<span data-form-id="' + formId + '" class="sample-url form-url">https://nrs.so/sample</span>')
        })
      }

      const urlMatches = message.match(/({URL_[0-9]+})/g)
      if (urlMatches) {
        urlMatches.forEach(match => {
          const urlId = match.match(/([0-9]+)/g)
          const url = this.availableUrls[urlId]?.url_preview
          message = message.replace('{URL_' + urlId + '}', '<span data-url-id="' + urlId + '"class="sample-url url-url"><a target="_blank" href="' + url + '">https://nrs.so/sample</a></span>')
        })
      }

      const unsubMatches = message.match(/({UNSUB_[0-9]+})/g)
      if (unsubMatches) {
        unsubMatches.forEach(match => {
          const urlId = match.match(/([0-9]+)/g)
          message = message.replace('{UNSUB_' + urlId + '}', '<span class="sample-url unsub-url">https://nrs.so/sample</span>')
        })
      }

      message = this.replaceUrlFields(message)
      message = message.replaceAll('{LAND_URL}', '<span class="sample-url land-url">https://nrs.so/sample</span>')
      message = message.replaceAll('{UNSUB_URL}', '<span class="sample-url unsub-url">https://nrs.so/sample</span>')

      message = this.textFormatParser.formatHtmlMessage(message)
      return message
    },
    textButtonAutoAnswerPreview: function () {
      const answers = this.campaign.sendings[0].channel.autoAnswer
      const found = answers.filter((item) => {
        return item.id === this.selectedAutoAnswerId
      })

      return found[0].label
    },
    messageAutoAnswerPreview: function () {
      const answer = this.campaign.sendings[0].channel.autoAnswer
      const found = answer.filter((item) => {
        return item.id === this.selectedAutoAnswerId
      })

      let message = found.length > 0 ? found[0].answer.body : ''
      if (!message) {
        return this.$t('Contenido del mensaje').toString()
      }
      message = this.textFormatParser.formatHtmlMessage(message)
      return message
    },
    footerAutoAnswerPreview: function () {
      const answer = this.campaign.sendings[0].channel.autoAnswer
      const found = answer.filter((item) => {
        return item.id === this.selectedAutoAnswerId
      })

      const footer = found.length > 0 ? found[0].answer.footer : ''

      if (footer !== '') {
        return footer
      }

      return false
    },
    validateHeaderAutoAnswerPreview () {
      const answer = this.campaign.sendings[0].channel.autoAnswer
      const found = answer.filter((item) => {
        return item.id === this.selectedAutoAnswerId
      })

      if (found[0].answerType === 'interactive') {
        if (found[0].answer.header.visible) {
          return true
        }
      }

      return false
    },
    typeHeaderAutoAnswerPreview: function () {
      const answer = this.campaign.sendings[0].channel.autoAnswer
      const found = answer.filter((item) => {
        return item.id === this.selectedAutoAnswerId
      })

      if (found[0].answerType === 'interactive') {
        return found[0].answer.header.type
      }

      return ''
    },
    headerAutoAnswerPreview: function () {
      const answer = this.campaign.sendings[0].channel.autoAnswer
      const found = answer.filter((item) => {
        return item.id === this.selectedAutoAnswerId
      })

      if (found[0].answerType === 'interactive') {
        return found[0].answer.header.url
      }

      return ''
    },
    headerAutoAnswerPreviewText: function () {
      const answer = this.campaign.sendings[0].channel.autoAnswer
      const found = answer.filter((item) => {
        return item.id === this.selectedAutoAnswerId
      })

      if (found[0].answerType === 'interactive') {
        return found[0].answer.header.content
      }

      return ''
    },
    validateButtonAutoAnswerPreview () {
      let response = false
      const answer = this.campaign.sendings[0].channel.autoAnswer
      const found = answer.filter((item) => {
        return item.id === this.selectedAutoAnswerId
      })

      if (found[0].answerType === 'interactive') {
        const buttons = found[0].answer.action.buttons
        if (buttons.length > 0) {
          buttons.forEach((button) => {
            if (button.type === 'quick_reply') {
              response = true
            }
          })
        }
      }

      return response
    },
    buttonAutoAnswerPreview () {
      const answer = this.campaign.sendings[0].channel.autoAnswer
      const found = answer.filter((item) => {
        return item.id === this.selectedAutoAnswerId
      })

      if (found[0].answerType === 'interactive') {
        return found[0].answer.action.buttons
      }

      return false
    },
    listAutoAnswerPreview () {
      const answer = this.campaign.sendings[0].channel.autoAnswer
      const found = answer.filter((item) => {
        return item.id === this.selectedAutoAnswerId
      })

      if (found[0].answerType === 'interactive') {
        return found[0].answer.action.sections
      }

      return false
    },
    validateListAutoAnswerPreview () {
      let response = false
      const answer = this.campaign.sendings[0].channel.autoAnswer
      const found = answer.filter((item) => {
        return item.id === this.selectedAutoAnswerId
      })

      if (found[0].answerType === 'interactive') {
        const buttons = found[0].answer.action.sections
        if (buttons.length > 0) {
          buttons.forEach((button) => {
            if (button.type === 'list') {
              response = true
            }
          })
        }
      }

      return response
    },
    listTitleAutoAnswerPreview () {
      const answer = this.campaign.sendings[0].channel.autoAnswer
      const found = answer.filter((item) => {
        return item.id === this.selectedAutoAnswerId
      })

      if (found[0].answerType === 'interactive') {
        return found[0].answer.action.buttonTitle
      }

      return ''
    },
    validateLocationAutoAnswerPreview () {
      let response = false
      const answer = this.campaign.sendings[0].channel.autoAnswer
      const found = answer.filter((item) => {
        return item.id === this.selectedAutoAnswerId
      })

      if (found[0].answerType === 'interactive') {
        const type = found[0].answer.type
        if (type === 2) {
          response = true
        }
      }

      return response
    },
    datePreview: function () {
      if (this.preview.contactsPhone.length > 0) {
        const contactFields = this.preview.contactsFieldsValue[this.preview.contactsPhone[0]]
        let field = this.campaign.sendings[0].automaticConfig.conditions.field
        if (this.campaign.recipientsSource !== 'contacts') {
          const index = parseInt(field) + 1
          field = 'COL' + index
        }
        return contactFields[field]
      }
      return ''
    },
    automaticResponse: function () {
      const data = this.campaign.sendings[0].channel.automaticResponses.filter((automaticResponse) => {
        return automaticResponse.visible !== false
      })

      return data
    },
    hasSelectedHeader: function () {
      return this.campaign.sendings[0].channel.selectedHeadboardOption
    },
    hasHeader: function () {
      return [
        HeadboardType.headboardTypes.HEADER_TEXT,
        HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
        HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
        HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
      ].includes(this.campaign.sendings[0].channel.selectedHeadboardType)
    },
    headerNeedsContentBox: function () {
      return [
        HeadboardOption.options.IMAGE,
        HeadboardOption.options.VIDEO,
        HeadboardOption.options.DOCUMENT,
        HeadboardOption.options.LOCATION,
      ].includes(this.campaign.sendings[0].channel.selectedHeadboardOption)
    },
    headerTextContent: function () {
      return JSON.parse(this.campaign.sendings[0].channel.headboard)
    },
  },
  created () {
    EventBus.$on('changeInteractiveType', () => {
      this.navigationDrawerList.show = false
    })
  },
  methods: {
    toggleNavigationDrawerOption () {
      this.navigationDrawerOption.show = !this.navigationDrawerOption.show
    },
    toggleNavigationDrawerList () {
      this.navigationDrawerList.show = !this.navigationDrawerList.show
    },
    formatHtmlMessage (origin, message) {
      for (let i = 0; i < message.length; i++) {
        switch (message[i]) {
          case this.htmlLabel.strong.format:
            message = this.setHtmlMessage(origin, 'strong', message, i)
            break
          case this.htmlLabel.em.format:
            message = this.setHtmlMessage(origin, 'em', message, i)
            break
          case this.htmlLabel.strike.format:
            message = this.setHtmlMessage(origin, 'strike', message, i)
            break
          case this.htmlLabel.code.format:
            message = this.setHtmlMessageCode(origin, 'code', message, i)
            break
          default:
            break
        }
      }

      return message
    },
    setHtmlMessage (origin, type, message, index) {
      if (this.htmlLabel[type].origin[origin].init === this.htmlLabel[type].origin[origin].finish) {
        this.htmlLabel[type].origin[origin].init = 1
        message = this.setCharAt(message, index, this.htmlLabel[type].end)
      } else {
        this.htmlLabel[type].origin[origin].init++
        message = this.setCharAt(message, index, this.htmlLabel[type].start)
      }

      return message
    },
    setHtmlMessageCode (origin, type, message, index) {
      if (this.htmlLabel[type].origin[origin].init === this.htmlLabel[type].origin[origin].finish && this.htmlLabel[type].origin[origin].row === 1) {
        this.htmlLabel[type].origin[origin].init = 1
        this.htmlLabel[type].origin[origin].row = 2
        message = this.setCharAtCode(message, index, this.htmlLabel[type].start)
      } else if (this.htmlLabel[type].origin[origin].init === this.htmlLabel[type].origin[origin].finish && this.htmlLabel[type].origin[origin].row === 2) {
        this.htmlLabel[type].origin[origin].init = 1
        this.htmlLabel[type].origin[origin].row = 1
        message = this.setCharAtCode(message, index, this.htmlLabel[type].origin[origin].end)
      } else {
        this.htmlLabel[type].origin[origin].init++
      }

      return message
    },
    setCharAt (str, index, chr) {
      if (index > str.length - 1) return str
      return str.substring(0, index) + chr + str.substring(index + 1)
    },
    setCharAtCode (str, index, chr) {
      if (index > str.length - 1) return str
      return str.substring(0, index - 2) + chr + str.substring(index + 1)
    },
    validateWhatsappHeaderType () {
      return [
        HeadboardType.headboardTypes.HEADER_TEXT,
        HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
        HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
        HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
      ].includes(this.campaign.sendings[0].channel.selectedHeadboardType)
    },
    validateWhatsappCallActionType () {
      if (
        this.campaign.sendings[0].channel.selectedHeadboardType === this.campaign.sendings[0].channel.headboardType.CALL_ACTION_TEXT ||
        this.campaign.sendings[0].channel.selectedHeadboardType === this.campaign.sendings[0].channel.headboardType.CALL_ACTION_HEADER_TEXT
      ) {
        return true
      }

      return false
    },
    validateWhatsappResponseType () {
      if (
        this.campaign.sendings[0].channel.selectedHeadboardType === this.campaign.sendings[0].channel.headboardType.RESPONSE_TEXT ||
        this.campaign.sendings[0].channel.selectedHeadboardType === this.campaign.sendings[0].channel.headboardType.RESPONSE_HEADER_TEXT
      ) {
        return true
      }

      return false
    },
    validateWhatsappOptionType () {
      if (
        this.campaign.sendings[0].channel.selectedHeadboardType === this.campaign.sendings[0].channel.headboardType.OPTION_TEXT ||
        this.campaign.sendings[0].channel.selectedHeadboardType === this.campaign.sendings[0].channel.headboardType.OPTION_HEADER_TEXT
      ) {
        return true
      }

      return false
    },
    showLanding () {
      this.$emit('showLanding')
    },
    changeHeadboardPreview (index) {
      const object = this.preview.contactsFieldsValue[index]
      let message = this.campaign.sendings[0].channel.headboard

      for (const property in object) {
        message = message.replace('{' + property + '}', object[property])
      }

      return message
    },
    changeWhatsAppPreview (index) {
      const object = this.preview.contactsFieldsValue[index]

      const contactFields = []
      for (const index in object) {
        contactFields[index] = object[index]
      }

      let message = this.campaign.sendings[0].channel.message
      message = this.replaceUrlFields(message)

      if (this.campaign.sendings[0].channel.customVerificationCodeField) {
        message = message.replace('{COL+}', contactFields[this.campaign.sendings[0].channel.customVerificationCodeField])
      }

      message = message.replaceAll('{LAND_URL}', '<span class="sample-url land-url">https://nrs.so/sample</span>')
      message = message.replaceAll('{UNSUB_URL}', '<span class="sample-url unsub-url">https://nrs.so/sample</span>')

      for (const property in object) {
        message = message.replaceAll('{' + property + '}', object[property])
      }

      message = message.replace(/null/g, this.$t('Sin datos'))
      message = this.textFormatParser.formatHtmlMessage(message)

      return message
    },
    // eslint-disable-next-line complexity
    replaceUrlFields (message) {
      for (let i = 0; i < this.fields.currentUrlFieldsMessage.length; i++) {
        const explodeUrlField = this.fields.currentUrlFieldsMessage[i].split('_')
        let value = ''
        switch (explodeUrlField[0]) {
          case 'FORM':
            value = this.generateSampleUrl(null, 'form-url', `data-form-id="${ explodeUrlField[1] }"`)
            break
          case 'UNSUB':
            value = this.generateSampleUrl(null, 'unsub-url')
            break
          case 'ATTACHMENT':
            value = this.generateSampleUrl(this.getAttachmentUrl(explodeUrlField[1]))
            break
          case 'URL':
            value = this.generateSampleUrl(this.availableUrls[explodeUrlField[1]]?.url_preview)
            break
          }
        if (!value) {
          this.generatedUrls[this.fields.currentUrlFieldsMessage[i]] = this.generatedUrls[this.fields.currentUrlFieldsMessage[i]] || this.generateSampleUrl()
          value = this.generatedUrls[this.fields.currentUrlFieldsMessage[i]]
        }
        if (['LAND_URL', 'UNSUB_URL'].indexOf(this.fields.currentUrlFieldsMessage[i]) < 0) {
          message = message.replace('{' + this.fields.currentUrlFieldsMessage[i] + '}', value)
        }
      }
      return message
    },
    getAttachmentUrl (field) {
      // eslint-disable-next-line no-case-declarations
      let attachment = this.campaign.sendings[0].channel.attachments.filter((attachmentIn) => {
        return parseInt(field) === attachmentIn.id
      })

      attachment = attachment[0]
      const url = attachment ? getEnv('backend_assets_url') + '' + attachment.url : null

      return url
    },
    generateRandomShortUrl (url = null) {
      const randStr = (Math.random() + 1).toString(36).substring(6)
      let randUrl = `https://nrs.so/${ randStr }`
      if (url) {
        randUrl = `<a target="_blank" href="${ url }">${ randUrl }</a>`
      }
      return `<span class="sample-url">${ randUrl }</span>`
    },
    generateSampleUrl (url = null, addClass = null, objectId = null) {
      let sampleUrl = 'https://nrs.so/sample'
      if (url) {
        sampleUrl = `<a target="_blank" href="${ url }">${ sampleUrl }</a>`
      }
      return `<span ${ objectId } class="sample-url ${addClass}">${ sampleUrl }</span>`
    },
    replaceUrls (message) {
      const allMatches = Array.from(message.matchAll(/\{URL_(.+?)\}/gmi))
      if (allMatches) {
        allMatches.forEach(match => {
          const [form, idform] = [...match]
          const url = this.availableUrls[idform]?.url_preview || null
          this.generatedUrls[form] = this.generatedUrls[form] || this.generateSampleUrl(url)
          message = message.replace(form, this.generatedUrls[form])
        })
      }
      return message
    },
    sendTestEvent () {
      EventBus.$emit('send-test-event')
    },
    handleClick (e) {
      if (e.target.matches('.land-url')) {
        this.$emit('showLanding')
      }

      if (e.target.matches('.form-url')) {
        const formId = e.target.getAttribute('data-form-id')
        this.$emit('showForm', formId)
      }

      if (e.target.matches('.url-url')) {
        const urlId = e.target.getAttribute('data-url-id')
        this.$emit('showUrl', urlId)
      }

      if (e.target.matches('.unsub-url')) {
        this.$emit('showUnsubscribe')
      }
    },
  },
}
